import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as adCampaignApi from '../../api/ads/campaign';
import { AdCampaignStatus } from '../../lib/AdCampaignStatus';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';

export const AD_CAMPAIGN_QUERY_KEYS = {
	fetchAllAdCampaigns: (status) => ['api', 'ad', 'campaign', status],
	fetchOneCampaignById: (adCampaignId) => ['api', 'ad', 'campaign', adCampaignId],
};

export const useFetchAdCampaigns = (status) => useQuery(
	AD_CAMPAIGN_QUERY_KEYS.fetchAllAdCampaigns(status),
	axiosQueryWrapper(adCampaignApi.fetchAllAdCampaigns, status),
	{ enabled: !!status },
);

export const useFetchAdCampaignById = (adCampaignId) => useQuery(
	AD_CAMPAIGN_QUERY_KEYS.fetchOneCampaignById(adCampaignId),
	axiosQueryWrapper(adCampaignApi.fetchOneAdCampaignById, adCampaignId),
	{ enabled: !!adCampaignId },
);

export const useCreateAdCampaign = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(adCampaignApi.createAdCampaign),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(
					AD_CAMPAIGN_QUERY_KEYS.fetchAllAdCampaigns(AdCampaignStatus.ACTIVE),
				);
				queryClient.invalidateQueries(
					AD_CAMPAIGN_QUERY_KEYS.fetchAllAdCampaigns(AdCampaignStatus.DRAFT),
				);
			},
		},
	);
};

export const useCreateDraftAdCampaign = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(adCampaignApi.createDraftAdCampaign),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(
					AD_CAMPAIGN_QUERY_KEYS.fetchAllAdCampaigns(AdCampaignStatus.ACTIVE),
				);
				queryClient.invalidateQueries(
					AD_CAMPAIGN_QUERY_KEYS.fetchAllAdCampaigns(AdCampaignStatus.DRAFT),
				);
			},
		},
	);
};
