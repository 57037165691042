import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParams = () => {
	const location = useLocation();
	return useMemo(() => new URLSearchParams(location.search), [location.search]);
};

export const useUpdateQueryParams = () => {
	const history = useHistory();

	return useCallback((newQuery) => {
		history.replace({
			search: newQuery.toString(),
		});
	}, [history]);
};

export const useAsyncErrorLog = ({ error }) => {
	useEffect(() => {
		if (error) console.error(error);
	}, [error]);
};
