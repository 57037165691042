// @ts-check
import { api } from '../api';

export const API_PUBLIC_LIVE_PATH = '/public/live';

/**
 * TODO: complete and move somewhere else
 * @typedef {{
 * 	_id: string,
 * 	code: string,
 * 	liveStatus: 'active' | 'live_stopped' | 'almost_finished',
 * 	organization: string,
 * 	startAt: Date,
 * 	status: string,
 * }} ILivePublic
 */

/**
 * @param {string} channelId
 * @returns {Promise<import('axios').AxiosResponse<ILivePublic[]>>}
 */
export const fetchAllComingUpLiveByChannelId = (channelId) => api.get(
	`${API_PUBLIC_LIVE_PATH}/byChannel/${channelId}/comingUp`,
);

/**
 * @param {string} channelId
 * @returns {Promise<import('axios').AxiosResponse<ILivePublic | undefined>>}
 */
export const fetchOneNextLiveByChannelId = (channelId) => api.get(
	`${API_PUBLIC_LIVE_PATH}/byChannel/${channelId}/next`,
);

/**
 * @returns {Promise<import('axios').AxiosResponse<ILivePublic[]>>}
 */
export const fetchComingUpLive = async () => api.get(
	`${API_PUBLIC_LIVE_PATH}/comingUp`,
);
