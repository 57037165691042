import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';
import * as organizationApi from '../../api/organization/organization';
import { useAuthentication } from '../../components/Authentication/Authentication';

export const ORGANIZATION_QUERY_KEYS = {
	fetchOrganizations: () => ['api', 'organization', 'fetch'],
	fetchOrganizationDetails: () => ['api', 'organization', 'details'],
	fetchOrganizationBusinessDetails: () => ['api', 'organization', 'business', 'details'],
	fetchOrganizationUserStatus: (organizationId) => ['api', 'organization', 'user', 'status', organizationId],
	fetchOrganizationMembers: () => ['api', 'organization', 'members'],
	fetchOrganizationMemberInvites: () => ['api', 'organization', 'members', 'invites'],
	fetchOrganizationMemberInviteById: (inviteId) => ['api', 'organization', 'members', 'invites', inviteId],
	fetchOrganizationMemberRequests: () => ['api', 'organization', 'members', 'requests'],
	fetchOrganizationRoles: () => ['api', 'organization', 'roles'],
	fetchOrganizationFreeTrialUsed: () => ['api', 'organization', 'trial', 'used'],
	fetchOrganizationPublicChannel: () => ['api', 'organization', 'public', 'channel'],
};

export const useFetchOrganizations = () => useQuery(
	ORGANIZATION_QUERY_KEYS.fetchOrganizations(),
	axiosQueryWrapper(organizationApi.fetchOrganizations),
);

export const useFetchOrganizationDetails = () => {
	const { isLoggedIn } = useAuthentication();

	return useQuery(
		ORGANIZATION_QUERY_KEYS.fetchOrganizationDetails(),
		axiosQueryWrapper(organizationApi.fetchOrganizationDetails),
		{ enabled: !!isLoggedIn },
	);
};

export const useFetchOrganizationPublicChannel = () => useQuery(
	ORGANIZATION_QUERY_KEYS.fetchOrganizationPublicChannel(),
	axiosQueryWrapper(organizationApi.fetchOrganizationPublicChannel),
);

export const useFetchOrganizationFreeTrialUsed = () => {
	const { isLoggedIn } = useAuthentication();

	return useQuery(
		ORGANIZATION_QUERY_KEYS.fetchOrganizationFreeTrialUsed(),
		axiosQueryWrapper(organizationApi.fetchOrganizationFreeTrialUsed),
		{ enabled: !!isLoggedIn },
	);
};

export const useFetchOrganizationUserStatus = (organizationId) => {
	const { isLoggedIn } = useAuthentication();

	return useQuery(
		ORGANIZATION_QUERY_KEYS.fetchOrganizationUserStatus(organizationId),
		axiosQueryWrapper(organizationApi.fetchOrganizationUserStatus, organizationId),
		{ enabled: !!organizationId && !!isLoggedIn },
	);
};

export const useUpdateOrganizationDetails = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(organizationApi.updateOrganizationDetails), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationDetails());
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationPublicChannel());
			},
		},
	);
};

export const useUpdateOrganizationSettings = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(organizationApi.updateOrganizationSettings), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationDetails());
			},
		},
	);
};

export const useUpdateOrganizationRolesOrder = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(organizationApi.updateOrganizationRolesOrder), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationRoles());
			},
		},
	);
};

export const useFetchOrganizationMembers = () => useQuery(
	ORGANIZATION_QUERY_KEYS.fetchOrganizationMembers(),
	axiosQueryWrapper(organizationApi.fetchOrganizationMembers),
);

export const useActivateOrgnizationMember = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.activateOrganizationMember), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMembers());
			},
		},
	);
};

export const useDisableOrgnizationMember = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.disableOrganizationMember), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMembers());
			},
		},
	);
};

export const useUpdateOrganizationMemberSettings = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.updateOrganizationMemberSettings), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMembers());
			},
		},
	);
};

export const useRemoveOrgnizationMember = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.removeOrganizationMember), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMembers());
			},
		},
	);
};

export const useFetchOrganizationMemberInvites = () => useQuery(
	ORGANIZATION_QUERY_KEYS.fetchOrganizationMemberInvites(),
	axiosQueryWrapper(organizationApi.fetchOrganizationMemberInvites),
);

export const useSendJoinOrganizationInvite = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.sendJoinOrganizationInvite), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMemberInvites());
			},
		},
	);
};

export const useRemoveJoinOrganizationInvite = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.removeJoinOrganizationInvite), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMemberInvites());
			},
		},
	);
};

export const useFetchOrganizationMemberRequests = () => useQuery(
	ORGANIZATION_QUERY_KEYS.fetchOrganizationMemberRequests(),
	axiosQueryWrapper(organizationApi.fetchOrganizationMemberRequests),
);

export const useRequestOrganizationJoin = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(organizationApi.requestOrganizationJoin), {
			onSuccess: (data) => {
				queryClient.invalidateQueries(
					ORGANIZATION_QUERY_KEYS.fetchOrganizationUserStatus(data.organization),
				);
			},
		},
	);
};

export const useAcceptOrganizationJoinRequest = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.acceptOrganizationJoinRequest), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMemberRequests());
			},
		},
	);
};

export const useDeclineOrganizationJoinRequest = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.declineOrganizationJoinRequest), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationMemberRequests());
			},
		},
	);
};

export const useFetchOrganizationRoles = () => useQuery(
	ORGANIZATION_QUERY_KEYS.fetchOrganizationRoles(),
	axiosQueryWrapper(organizationApi.fetchOrganizationRoles),
);

export const useCreateOrganizationRole = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.createOrganizationRole), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationRoles());
			},
		},
	);
};

export const useUpdateOrganizationRole = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.updateOrganizationRole), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationRoles());
			},
		},
	);
};

export const useMakeOrganizationRoleDefault = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.makeOrganizationRoleDefault), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationRoles());
			},
		},
	);
};

export const useDeleteOrganizationRole = () => {
	const queryClient = useQueryClient();
	return useMutation(
		axiosMutationWrapper(organizationApi.deleteOrganizationRole), {
			onSuccess: () => {
				queryClient.invalidateQueries(ORGANIZATION_QUERY_KEYS.fetchOrganizationRoles());
			},
		},
	);
};

export const useEnterOrganization = () => {
	const { switchTokens } = useAuthentication();
	const history = useHistory();

	return useMutation(
		axiosMutationWrapper(organizationApi.enterOrganization), {
			onSuccess: (data) => {
				switchTokens(data);
				history.push('/');
				window.location.reload();
			},
		},
	);
};

export const useJoinOrganization = () => {
	const { switchTokens } = useAuthentication();
	const history = useHistory();

	return useMutation(
		axiosMutationWrapper(organizationApi.joinOrganization), {
			onSuccess: (data) => {
				switchTokens(data);
				history.push('/');
				window.location.reload();
			},
		},
	);
};
