// @ts-check
import jstz from 'jstz';
import { identity } from '../../components/Authentication/Authentication';
import { IdentityError } from '../../lib/Identity';
import { api } from '../api';

export const API_PAGEVIEW_PATH = '/stats/pageview';

/**
 * @returns {{
 *  ancestorOrigins?: string[],
 *	href: string,
 *	referrer?: string,
 * }}
 */
function getLocationData() {
	const { location } = window;
	const { referrer } = document;
	return {
		ancestorOrigins: location.ancestorOrigins && Array
			.from(location.ancestorOrigins)
			.map((origin) => origin.toString()),
		href: location.href,
		referrer: referrer || undefined,
	};
}

export const postPageViewData = async (path) => {
	let authorization;
	const browserLanguage = navigator.language;
	const browserTimezone = jstz.determine().name();
	const locationData = getLocationData();

	try {
		const { token } = await identity.getAccessToken();
		authorization = `Bearer ${token}`;
	} catch (e) {
		if (!(e instanceof IdentityError)) throw e;
		// WARNING: hidden error access_token_not_found
		if (e.type !== 'access_token_not_found') console.warn(e);
	}

	return api.post(
		API_PAGEVIEW_PATH,
		{
			path,
			browserLanguage,
			browserTimezone,
			location: locationData,
		},
		{
			headers: {
				authorization,
			},
		},
	);
};
