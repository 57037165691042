// @ts-check

import { useQuery } from 'react-query';
import * as searchApi from '../api/search';
import { axiosQueryWrapper } from './utils/axios-wrapper';

/** @import { APISearchParams } from '../api/search' */

export const SEARCH_QUERY_KEYS = {
	/**
	 * @param {string} query
	 * @param {Omit<APISearchParams, 'q'>} opts
	 */
	fetchSearchResults: (query, opts) => ['api', 'search', 'results', query, opts],
	/** @param {string} query */
	fetchSearchSuggestions: (query) => ['api', 'search', 'suggestion', query],
	fetchUserSuggestions: (query, opts) => ['api', 'search', 'userSuggestion', query, opts],
	fetchLibrarySuggestions: (query, opts) => ['api', 'search', 'librarySuggestion', query, opts],
};

/**
 * @param {string | undefined} query
 * @param {Omit<APISearchParams, 'q'>} opts
*/
export const useFetchSearchResults = (query, opts) => useQuery(
	SEARCH_QUERY_KEYS.fetchSearchResults(encodeURI(query || ''), opts),
	axiosQueryWrapper(searchApi.fetchSearchResults, encodeURI(query || ''), opts),
	{ enabled: !!query },
);

/**
 * @param {string | undefined} query
*/
export const useFetchSearchSuggestions = (query) => useQuery(
	SEARCH_QUERY_KEYS.fetchSearchSuggestions(encodeURI(query || '')),
	axiosQueryWrapper(searchApi.fetchSearchSuggestions, encodeURI(query || '')),
	{ enabled: !!query },
);

export const useFetchUserSuggestions = (query, opts) => useQuery(
	SEARCH_QUERY_KEYS.fetchUserSuggestions(encodeURI(query), opts),
	axiosQueryWrapper(searchApi.fetchUserSuggestions, encodeURI(query), opts),
	{ enabled: !!query },
);

/**
 * @param {string} query
 * @param {{
* 	channelId: string,
*  libraryFilters: import('../components/Studio/Search/helper').SearchLibraryContentType[],
* }} opts
 */
export const useFetchLibrarySuggestions = (query, opts) => useQuery(
	SEARCH_QUERY_KEYS.fetchLibrarySuggestions(encodeURI(query), opts),
	axiosQueryWrapper(searchApi.fetchLibrarySuggestions, encodeURI(query), opts),
	{ enabled: !!query },
);
